import axios from 'axios'
import { captureException } from '@sentry/vue'

interface UploadResponse {
    result: {
        id: string
    }
}

interface UploadUrlResponse {
    id: string
    uploadURL: string
}

interface VaporUploadResponse {
    key: string
    url: string
    headers: {
        Host: string[]
    }
}

async function getUploadUrl(): Promise<string> {
    const response = await axios.get<UploadUrlResponse>(
        window.route('upload-url.cloudflare-images'),
    )

    return response.data.uploadURL
}

/**
 *
 * @param file
 * @param onProgress Will be updated with progress for the upload. The `progress` is a number between 0 and 1.
 */
export async function cloudflareUpload(
    file: File,
    onProgress?: (progress: number) => void,
): Promise<string> {
    try {
        const uploadUrl = await getUploadUrl()
        const formData = new FormData()
        formData.append('file', file!)

        const response = await axios.post<UploadResponse>(uploadUrl, formData, {
            withCredentials: false,
            onUploadProgress(event) {
                if (!onProgress) return

                onProgress(event.loaded / (event.total || 1))
            },
            headers: { 'Content-Type': 'multipart/form-data' },
        })

        return response.data.result.id
    } catch (e) {
        console.error(e)
        captureException(e)

        // In case it fails we'll fall back to S3 upload
        const vaporResponse = await vaporUpload(file, onProgress)
        // We need to return the full url as the image function would append the Cloudflare domain if not
        return extractVaporUrl(vaporResponse)
    }
}

/**
 *
 * @param file
 * @param onProgress Will be updated with progress for the upload. The `progress` is a number between 0 and 1.
 */
export async function vaporUpload(
    file: File,
    onProgress?: (progress: number) => void,
): Promise<VaporUploadResponse> {
    const response = await window.Vapor.store(file, {
        progress: onProgress,
        visibility: 'public-read',
        httpClient: axios,
    })

    return response as VaporUploadResponse
}

export async function extractVaporUrl(response: VaporUploadResponse) {
    // Since we upload files using public-read visibility we can use the urls without params
    return response.url.split('?', 1)[0]
}
