import axios, { AxiosInstance, AxiosRequestConfig, CancelToken, AxiosResponse } from 'axios'

let assetUrlResolver = (): string => {
    try {
        return process.env.MIX_VAPOR_ASSET_URL ?? ''
    } catch (e) {
        console.error(
            'Unable to automatically resolve the asset URL. Use Vapor.withBaseAssetUrl() to specify it manually.',
        )
        throw e
    }
}

interface StoreOptions {
    bucket?: string
    contentType?: string
    expires?: string
    visibility?: string
    data?: Record<string, unknown>
    progress?: (progress: number) => void
    signedStorageUrl?: string
    baseURL?: string | null
    headers?: Record<string, string>
    options?: AxiosRequestConfig
    cancelToken?: CancelToken
    httpClient?: AxiosInstance
}

interface StoreResponse {
    uuid: string
    key: string
    url: string
    headers: Record<string, string>
    extension?: string
    [key: string]: unknown
}

class VaporClient {
    /**
     * Generate the S3 URL to an application asset.
     */
    asset(path: string): string {
        return `${assetUrlResolver()}/${path}`
    }

    /**
     * Set the base URL for assets.
     */
    withBaseAssetUrl(url: string): void {
        assetUrlResolver = () => url ?? ''
    }

    /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
    async store(file: File, options: StoreOptions = {}): Promise<StoreResponse> {
        const httpClient = options.httpClient ?? axios

        const response: AxiosResponse<StoreResponse> = await httpClient.post(
            options.signedStorageUrl ?? '/vapor/signed-storage-url',
            {
                bucket: options.bucket ?? '',
                content_type: options.contentType ?? file.type,
                expires: options.expires ?? '',
                visibility: options.visibility ?? '',
                ...(options.data ?? {}),
            },
            {
                baseURL: options.baseURL ?? '',
                headers: options.headers ?? {},
                withCredentials: true,
                ...(options.options ?? {}),
            },
        )

        const headers = { ...response.data.headers }
        delete headers.Host

        await httpClient.put(response.data.url, file, {
            cancelToken: options.cancelToken,
            headers,
            onUploadProgress: (event) => {
                if (event.total) {
                    options.progress?.(event.loaded / event.total)
                }
            },
        })

        response.data.extension = file.name.split('.').pop()

        return response.data
    }
}

export default new VaporClient()
